<template>
    <div class="container-fluid d-flex bg-img notfound lighter--text">
        
        <div class="notfound-circle">
        <div class="text-center"> <b-img
          :src="require('@/assets/logo.svg')"
          style=""
          class="logo-circle"
        /></div>
        <div class="nb-error">
        
            <div class="error-code mt-4 mb-2">4
                <svg class="sad-icon lighter--fill" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 368 368" style="enable-background:new 0 0 368 368;" xml:space="preserve">

		<g>
			<path d="M184,0C82.544,0,0,82.544,0,184s82.544,184,184,184c101.464,0,184-82.544,184-184S285.464,0,184,0z M184,352
				c-92.64,0-168-75.36-168-168S91.36,16,184,16c92.632,0,168,75.36,168,168S276.632,352,184,352z"/>
			<path d="M144,152c0-13.232-10.768-24-24-24s-24,10.768-24,24s10.768,24,24,24S144,165.232,144,152z M112,152c0-4.408,3.592-8,8-8
				s8,3.592,8,8s-3.592,8-8,8S112,156.408,112,152z"/>
			<path d="M248,128c-13.232,0-24,10.768-24,24s10.768,24,24,24s24-10.768,24-24S261.232,128,248,128z M248,160
				c-4.416,0-8-3.592-8-8s3.584-8,8-8c4.416,0,8,3.592,8,8S252.416,160,248,160z"/>
			<path d="M184,224c-29.824,0-58.24,12.632-77.96,34.664c-2.944,3.296-2.664,8.352,0.624,11.296
				c3.296,2.952,8.352,2.664,11.296-0.624C134.648,250.688,158.72,240,184,240c25.28,0,49.352,10.688,66.04,29.336
				c1.576,1.768,3.768,2.664,5.96,2.664c1.896,0,3.816-0.672,5.336-2.04c3.288-2.944,3.568-8,0.624-11.296
				C242.24,236.64,213.832,224,184,224z"/>
		</g>
        </svg> 4</div>

            <h3 class="font-bold mb-3 error-title">{{ $t('page_not_found') }}</h3>
    
            <div class="error-desc mt-3">
                              <div class="" style="text-center">  <b-button style="margin: auto;margin-top: 0" size="sm" variant="primary" pill  @click="$router.push('/')" class="d-block d-md-none">{{$t('back_to_home')}}</b-button></div>

                <span v-html="$t('back_to_home')" class="d-none d-md-block"></span>
<div class="mt-4 text-center">
                <b-button variant="primary" pill  @click="$router.push('/')" class="home404 d-none d-md-block"><svg
     
      class="home-icon pointer white--fill"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <g>
        <path
          d="M503.401,228.884l-43.253-39.411V58.79c0-8.315-6.741-15.057-15.057-15.057H340.976c-8.315,0-15.057,6.741-15.057,15.057
			v8.374l-52.236-47.597c-10.083-9.189-25.288-9.188-35.367-0.001L8.598,228.885c-8.076,7.36-10.745,18.7-6.799,28.889
			c3.947,10.189,13.557,16.772,24.484,16.772h36.689v209.721c0,8.315,6.741,15.057,15.057,15.057h125.913
			c8.315,0,15.057-6.741,15.057-15.057V356.931H293v127.337c0,8.315,6.741,15.057,15.057,15.057h125.908
			c8.315,0,15.057-6.741,15.056-15.057V274.547h36.697c10.926,0,20.537-6.584,24.484-16.772
			C514.147,247.585,511.479,236.245,503.401,228.884z M433.965,244.433c-8.315,0-15.057,6.741-15.057,15.057v209.721h-95.793
			V341.874c0-8.315-6.742-15.057-15.057-15.057H203.942c-8.315,0-15.057,6.741-15.057,15.057v127.337h-95.8V259.49
			c0-8.315-6.741-15.057-15.057-15.057H36.245l219.756-200.24l74.836,68.191c4.408,4.016,10.771,5.051,16.224,2.644
			c5.454-2.41,8.973-7.812,8.973-13.774V73.847h74.002v122.276c0,4.237,1.784,8.276,4.916,11.13l40.803,37.18H433.965z"
        />
      </g>
    </svg></b-button>
    </div>
               
            </div>
        </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import Vue from 'vue';

    export default Vue.extend({});
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.notfound{
   padding:0;
    min-height: 100vh !important;
    .notfound-circle{
        border-radius: 50%;
        background: #00000029;
        width: 90vw;
    max-width: 700px;
    height: 700px;
    max-height: 90vw;
    position: relative;
    margin:auto;
    padding-top:150px;
    border: 2px solid red;
    .logo-circle{
        width: 400px;
    }
    }
     .nb-error {
        margin: 0 auto;
        text-align: center;
        max-width: 640px;
        padding: 60px 30px;
    }

    .nb-error .error-code {
        font-size: 200px;
        line-height: 100px;
    }

    .nb-error .error-desc {
        font-size: 14px;
    }
   
    .home404{
        height: 80px;
    width: 80px;
      margin: auto;
    }
       .sad-icon{
       width: 150px;
    max-width: 200px;
    position: relative;
    top: -20px;
    path:first-of-type{
        fill:red;
    }
    }
}
  @media(max-width:777px){
      .notfound{
       .notfound-circle{
        
        width: 90vw;
    height: 90vw;
  
    padding-top:15vw;
    .logo-circle{
        width: 50vw;
    }
    }
     .nb-error {
        margin: 0 auto;
        text-align: center;
        max-width: 640px;
        padding: 11vw 0;
    }

    .nb-error .error-code {
        font-size: 28vw;
        line-height: 100px;
            margin: 0 auto !important;
    }

    .nb-error .error-desc {
        font-size: 2vw;
        margin-top: 0vw !important;
    }
   .error-title{
       font-size: 4vw;
       margin-top: 1vw;
    margin-bottom: 3vw !important;
   }
    .home404{
        height: 80px;
    width: 80px;
  
    }
       .sad-icon{
       width: 24vw;
    position: relative;
    top: -3.5vw;
    path:first-of-type{
        fill:red;
    }
    }
  } 
  }
</style>
